.products-section {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  width: 80%;
  column-gap: 64px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin: 28px auto 70px;
}

.products__title {
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.12px;
}
.Links{
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 700px) {
  .products__container {
    justify-content: center;
    width: 100%;
  }
}