.logo-carousel {
  width: 100%;
  height: 10rem;
  margin: auto;
}

.logo-carousel-item {
  background-color: white;
  height: 10rem;
}

.logo-carousel-img {
  margin: 7rem;
  margin-top: 12rem;
  width: 50%;
  aspect-ratio: 3/2;
  object-fit: contain;
  margin-left: 13rem;
}

.logo-container {
  display: flex;
  gap: 1rem;
  max-height: 64px;
  justify-content: space-between;
  align-items: center;
  max-width: 220px;
  margin-left: 32px;
  margin-top: 0.5rem;
}

.logo-carousel .carousel-control-prev-icon,
.logo-carousel .carousel-control-next-icon {
  filter: invert(100%);
}

@media (max-width: 767px) {
  .logo-carousel {
    width: 100%;
    height: 4rem;
  }
  .logo-carousel-img {
    width: 25%;
    margin: 0.5rem;
  }
  .logo-carousel-item {
    background-color: white;
    height: 4rem;
  }

  .logo-container {
    margin-left: 12%;
    margin-top: 2%;
    margin-right: auto;
    justify-content: space-between;
    gap: 0.5rem;
    height: 3rem;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    transform: scale(0.8);
  }
}
