.about-us-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: left;
  background: linear-gradient(90deg, #ffffff 5%, #20dffd 40%, #ffffffa6 70%, #ffffff 80%);
  background-clip: text;
  width: 70%;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0.2);
  font-size: 2.1rem;
  font-style: normal;
  font-weight: 500;
  margin: 16px auto;
}

.about-us-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: left;
}

.about-us-container p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}

.about-us-section {
  margin-bottom: 40px;
}

.about-us-section h2 {
  font-size: 28px;
  margin-bottom: 15px;
}
.about-us-section p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}

.about-us-section ul {
  list-style-type: none;
  padding: 0;
}

.about-us-section ul li {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.mesh-bg {
  height: 600px;
  width: 533px;
}
@media (max-width: 767px) {
  .about-us-container {
    text-align: center;
  }
  .preview-mesh {
    display: none !important;
  }
}
