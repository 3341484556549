.card-container.services-card {
  width: 300px;
  height: 350px;
  margin: auto;
}

.card-container.products-card {
  width: 300px;
  margin: auto;
  min-height: 400px;
  border-radius: 32px;
  background: #f7f5fb;
}

.card-data-container {
  width: 100%;
  height: 90%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.card-main {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin: 15px 15px 0;
}

.services-card .card-data-container {
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 10px 20px -10px rgba(18, 147, 254, 1);
}

.card-image {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card-desc {
  color: #4f4f4f;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  margin: 0 15px 15px;
}

.services-card .card-image {
  width: 61px;
  height: 61px;
  border-radius: 50%;
}

.products-card .card-image {
  width: 150px;
  height: 130px;
  background-color: white;
  max-width: 190px;
  max-height: 165px;
  border-radius: 30px;
}


