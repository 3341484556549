.carousel {
  width: 100%;
  position: relative;
}

.carousel-item {
  width: 100%;
  height: 630px;
  background-color: black;
  color: black;
  position: relative;
}

.carousel-opacity img {
  opacity: 0.6;
  border: 3px solid black;
}

.caption {
  color: white;
  font-weight: 100;
  font-size: xx-large;
}

.caption h1 {
  margin-bottom: 11%;
  margin-top: -4%;
}

.ml-7 {
  margin-left: 5%;
  font-size: 63px;
  text-align: center;
  font-weight: 500;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .carousel-item {
    height: 300px;
  }

  .caption {
    font-size: large;
  }

  .caption h1 {
    margin-bottom: 5%;
    margin-top: -2%;
    font-size: 24px;
  }

  .ml-7 {
    margin-left: 2%;
    font-size: 20px;
  }

  .carousel-caption {
    top: 45%;
    transform: translateY(-45%);
  }
  .carousel-inner{
    height: 22rem!important;
  }
}
.carousel-inner{
  height: 44rem;
}
.carousel .carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px; 
}

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel .carousel-indicators .active {
  opacity: 1;
  background-color: #007bff;
}
