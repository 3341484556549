.ain-footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 2px solid #e6e6e6;
  padding-top: 30px;
}

.ain-footer-location {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  gap: 20px;
}

.ain-footer-location__map {
  flex: 1;
}

.ain-footer-location__map .map_frame {
  width: 100%;
  min-width: 350px;
  min-height: 250px;
  height: 100%;
  border-radius: 20px;
}

.footer-contact-section {
  flex: 1;
  padding: 0 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.contact-info {
  display: flex;
  gap: 12px;
  align-items: center;
}

.bootstrap-icon img {
  width: 100%;
  height: 100%;
}

.get-in-touch {
  font-size: 1.5rem;
  font-weight: 700;
}

.ain-footer-links {
  display: flex;
  width: 90%;
  margin: 40px auto;
  border-top: 1px solid #e6e6e6;
  padding-top: 20px;
}

.ain-footer-links-logo {
  width: 30%;
  max-width: 190px;
}

.logo-footer {
  width: 100%;
}

.ain-footer-links-redirects {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
}

.ain-footer-links-redirects .nav-item {
  position: relative; /* Ensure the dropdown is positioned relative to the nav item */
  height: fit-content;
}

.nav-item .dropdown {
  position: relative;
}

.nav-item .dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu1 {
  display: none;
  position: absolute; /* Position the dropdown absolutely */
  bottom: 100%; /* Align it to the top of the parent */
  left: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border: 1px solid #ddd;
  padding: 10px;
  min-width: 200px;
  border-radius: 4px;
}

.dropdown-item {
  padding: 5px 10px;
  color: black;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.aboutUs {
  text-decoration: none;
  color: #000000;
}

.email-link:hover {
  color: blue;
  text-decoration: underline;
}

.email-link:hover {
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .ain-footer-location {
    justify-content: center;
  }
}

.social-media-wrapper {
  padding: 20px;
}

.social-media-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 24px auto;
  gap: 24px 32px;
}

.social-media-icon a {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
}

.social-media-icon:hover .clickable {
  cursor: pointer;
}

.facebook path {
  color: #1877f2;
}

.twitter path {
  color: #000000;
}

.youtube path {
  color: #ff0000;
}

.linkedin path {
  color: #0a66c2;
}

.instagram path {
  color: #c32aa3;
}

.pinterest path {
  color: #bd081c;
}


.dropdown .nav-item{
  text-decoration: none;
  color:black
}