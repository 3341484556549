.header-container {
  width: 100%;
  display: flex;
  margin-top: 18px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 2rem;
  background-color: #445742;
}

.header-logo-container {
  max-height: 64px;
  display: flex;
  align-items: center;
  max-width: 220px;
  margin-left: 32px;
  background-color: white;
}

.logo {
  height: 64px;
}

.nav-container {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  /* margin-top: 1rem; */
}

.header-container .nav-item{
  display: flex;
  padding-right: 16px;
  /* padding-top: -10px; */
  align-items: center;
  font-size: 1rem;
  /* border: 1px solid white; */
  justify-content: space-between;
  background-color: white;
  text-decoration: none;
  color: white;
  background-color: #445742;
}

.nav-item:hover {
  cursor: pointer;
  font-weight: 800;
  /* border-bottom: 1px solid black; */
  color: black;
}

.button-container {
  max-height: 48px;
  margin-right: 32px;
}

.call-button {
  padding: 12px 24px;
  border-radius: 88px;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  background: linear-gradient(86.44deg, #0038ff 2.93%, #0038ff 2.94%, #20dffd 97.07%);
}

.call-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .header-container {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .header-logo-container {
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .nav-container {
    margin-left: 0.3rem;
  }
}

/* Dropdown Styles */
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  white-space: nowrap;
}

.dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-item {
  padding: 8px 16px;
  text-decoration: none;
  color: black;
  background-color: white;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  font-weight: 800;
}

/* Ensure dropdown stays within viewport */
.dropdown-menu {
  right: 0;
  left: auto;
}

@media (max-width: 768px) {
  .dropdown-menu {
    right: auto;
    left: 0;
    transform: translateX(-20%);
  }
}
