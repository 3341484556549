.App {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divider {
    border-left: 2px solid rgb(195, 195, 195);
}

@media screen and (max-width: 700px) {
    .title-2 {
        font-size: 3.5rem !important;
    }
    .service-header__title, .products__title {
        font-size: 2.5rem !important;
    }
}